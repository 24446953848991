import React from "react"
import Navbar from "../components/navbar"
import SEO from "../components/SEO"

export default function Error(location) {
    return (
      <main>
        <SEO title="Not Found" pathname={location.pathname} />

        <Navbar></Navbar>

        <div className="lg:max-w-screen-md mx-auto p-6 pt-32">
          <img src="/error.svg" srcSet="/error.svg" alt="" loading="lazy" />
        </div>
      </main>
    )
}